//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex'

export default {
    data() {
        return {
            upsellItems: [],
            oloUpsells: []
        }
    },
    mounted() {
        $nuxt.$on('fetchUpsells', () => {
            this.loadUpsells()
        })
    },
    async fetch() {
        await this.loadUpsells()
    },
    methods: {
        addToDataLayersGA(product, location, eventType) {
            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            dataLayer.push({
                event: eventType,
                ecommerce: {
                    currency: "USD",
                    value: product.price,
                    items: [{
                        item_id: product.id,
                        item_name: product.name,
                        item_category: product.cat,
                        location_id: location.id,
                        price: product.price,
                        quantity: 1
                    }]
                }
            });
        },
        async loadUpsells() {
            try {
                const upsells = await this.$api.getUpsells(this.activeOrder.id)
                this.upsellItems = upsells

                this.oloUpsells =
                    this.upsellItems.map((p) => {
                        return {
                            name: p.name,
                            id: p.id,
                            quantity: 0,
                            price: `$${p.cost.toFixed(2)}`,
                            cat: p.category,
                            product: this.productList(p.id)
                        }
                    }) || []
            } catch (error) {
                console.log(error)
            }
        },
        async addUpsell(item, i) {
            this.$store.commit('setLoading', true)
            // console.log(this.activeOrder)
            try {
                // const prodmatch = this.activeOrder.products.find(p => p.product_id === item.id)
                //   console.log(prodmatch);
                const items = [
                    {
                        id: item.id,
                        quantity: 1
                    }
                ]

                const order = await this.$api.addUpsells(this.activeOrder.id, items)
                if (order.action) {
                    // console.log('need a redirect here for id ', order.prodId)
                    this.$store.commit('setLoading', false)
                    this.$store.commit('setGlobalCart', false)
                    this.$router.push(`/order/product?id=${item.product.sku}`)
                } else {
                    this.$store.commit('setActiveOrder', order)
                    this.$store.commit('setLoading', false)
                    this.addToDataLayersGA(item, this.$store.state.location, "add_to_cart")
                    //  const prod = this.activeOrder.products.find(p => p.product_id === item.id)
                    //  item.quantity = prod ? prod.quantity : 1
                    await this.loadUpsells()
                }
                // this.$store.commit('setActiveOrder', order)
                // this.$store.commit('setLoading', false)
                // this.addToDataLayersGA(item, this.$store.state.location, "add_to_cart")
                // //  const prod = this.activeOrder.products.find(p => p.product_id === item.id)
                // //  item.quantity = prod ? prod.quantity : 1
                // await this.loadUpsells()
            } catch (error) {
                this.$store.commit('setLoading', false)
                console.log('error in upsells: ', error)
            }
        },
        async subtractUpsell(item, i) {
            this.$store.commit('setLoading', true)
            try {
                const items = [
                    {
                        id: item.id,
                        quantity: this.oloUpsells[i].quantity - 1
                    }
                ]

                const order = await this.$api.addUpsells(this.activeOrder.id, items)
                const prod = this.activeOrder.products.find(
                    (p) => p.product_id === item.id
                )
                item.quantity = prod ? prod.quantity : 1

                this.$store.commit('setLoading', false)
                this.$store.commit('setActiveOrder', order)
            } catch (error) {
                this.$store.commit('setLoading', false)
                console.log(error)
            }
        }
    },
    computed: {
        ...mapState(['activeOrder', 'activeMenu', 'globalCart']),
        ...mapGetters(['productList'])
    }
}
